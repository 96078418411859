import {ANNOTATION_VIDEO_DISPLAY, ANNOTATION_PARENT, VIDEO_PLAYING_MODE, ANNOTATION_LOCATION} from './GlobalConstants';
import * as THREE from 'three';

export const ANNOTATION_CONTACT_TEXT = '';
export const CONTACT_URL = 'WEBSITE';
export const YOUTUBE = 'YOUTUBE';
export const INSTAGRAM = 'INSTAGRAM';
export const SPOTIFY = 'SPOTIFY';
export const TWITTER = 'TWITTER';
export const TIKTOK = 'TIKTOK';
export const IMDB = 'IMDB';
export const FACEBOOK = 'FACEBOOK';
export const ENQUIRE_EMAIL = 'ENQUIRE';
export const LINKED_IN = 'LINKEDIN';
export const HAS_MEDIA_CAROUSEL_LINK = 'MORE';
export const SEND_TO_EMAIL = 'SEND TO';


export const CANTALOUPE_IMAGE_SERVER = "https://iiif.v21artspace.com/iiif/2/wwf%2Fjust-imagine%2F";

export const ANNOTATION_TEXT_ALIGNMENT = "justify";
export const ANNOTATION_TEXT_LINE_HEIGHT = "1.5";
export const GALLERY_LINK_LABEL = "ARTIST-PROFILE";
// export const GALLERY_LINK_LABEL = `<img src='/assets/languages/iconfinder_uk.png'>`;


const AnnotationsDB = [

    /* Video */
    {
        paintingId: 'LC01V',
        videoId: 'LC01V',
        standaloneVideoElementId: 'LC01V',
        videoURL: 'LC01V.mp4',
        // iiifSource: `${CANTALOUPE_IMAGE_SERVER}.jpg/info.json`,
        headerText: '<h5>The Foundling</h5>',
        bodyText: `The Foundling gives voice to a boy who has experienced abandonment, separation and loss. To make sense of a human world of war and pollution, he turns to the piano and his dreams of nature. Here, anything is possible and the world is full of birds who fly free. It is a one-minute experimental documentary animated in charcoal. It was hand-drawn, with each frame drawn over the one before. It was commissioned by Animate Projects/Anim18 and created at Figuration, London.`,
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        displayMesh: 'N',
        // displayHeight: 90,
        videoAutoPlay: 'N',
        // videoPlaySpriteImageURL: '/assets/sprites/videoPlayButtonBlack.svg',
        // videoPauseSpriteImageURL: '/assets/sprites/videoPauseButtonBlack.svg',
        // videoButtonScale: {x: .2, y: .2, z: .2},
        // videoButtonPos:{x:9.17728, y:0.606917,z:11.6652},
        videoTextureEncoding: THREE.sRGBEncoding,
        // videoButtonPos:{x:-8.31443,y:-1.5 ,z:-29.7395 }
        useVideoMeshButton: 'Y',
    },
    /* Video */

    /* Audio: Background */
    {
        paintingId: 'BACA01',
        videoId: 'BACA01',
        standaloneVideoElementId: 'BACA01',
        // videoURL: 'AlA01.mp3',
        videoURL: 'BACA01.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'N',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_WITH_BUTTONS,
        displayAudioSprite: 'N',
        videoLoop: true,
        videoAutoPlayDelay: 0,
        videoButtonScale: {x:.05,y:.05,z:1},
        video_button_z_offset: .01,
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    /* Audio: Background */

    /* Audio: Wall Text */
    {
        paintingId: 'ABWT01A',
        videoId: 'ABWT01A',
        standaloneVideoElementId: 'ABWT01A',
        videoURL: 'ABWT01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'EXWT01A',
        videoId: 'EXWT01A',
        standaloneVideoElementId: 'EXWT01A',
        videoURL: 'EXWT01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    /* Audio: Wall Text */

    /* Audio: Captions */
    {
        paintingId: 'AL01CA',
        videoId: 'AL01CA',
        standaloneVideoElementId: 'AL01CA',
        videoURL: 'AL01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'AN01CA',
        videoId: 'AN01CA',
        standaloneVideoElementId: 'AN01CA',
        videoURL: 'AN01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'AP01CA',
        videoId: 'AP01CA',
        standaloneVideoElementId: 'AP01CA',
        videoURL: 'AP01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'AY01CA',
        videoId: 'AY01CA',
        standaloneVideoElementId: 'AY01CA',
        videoURL: 'AY01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'BY01CA',
        videoId: 'BY01CA',
        standaloneVideoElementId: 'BY01CA',
        videoURL: 'BY01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'DO01CA',
        videoId: 'DO01CA',
        standaloneVideoElementId: 'DO01CA',
        videoURL: 'DO01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'IS01CA',
        videoId: 'IS01CA',
        standaloneVideoElementId: 'IS01CA',
        videoURL: 'IS01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'JC01CA',
        videoId: 'JC01CA',
        standaloneVideoElementId: 'JC01CA',
        videoURL: 'JC01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'LC01CA',
        videoId: 'LC01CA',
        standaloneVideoElementId: 'LC01CA',
        videoURL: 'LC01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'MA01CA',
        videoId: 'MA01CA',
        standaloneVideoElementId: 'MA01CA',
        videoURL: 'MA01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'NE01CA',
        videoId: 'NE01CA',
        standaloneVideoElementId: 'NE01CA',
        videoURL: 'NE01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'SS01CA',
        videoId: 'SS01CA',
        standaloneVideoElementId: 'SS01CA',
        videoURL: 'SS01CA.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    /* Audio: Captions */

    /* Audio: ArtWork */
    {
        paintingId: 'AL01A',
        videoId: 'AL01A',
        standaloneVideoElementId: 'AL01A',
        videoURL: 'AL01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'AN01A',
        videoId: 'AN01A',
        standaloneVideoElementId: 'AN01A',
        videoURL: 'AN01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'AP01A',
        videoId: 'AP01A',
        standaloneVideoElementId: 'AP01A',
        videoURL: 'AP01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'AY01A',
        videoId: 'AY01A',
        standaloneVideoElementId: 'AY01A',
        videoURL: 'AY01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'BY01A',
        videoId: 'BY01A',
        standaloneVideoElementId: 'BY01A',
        videoURL: 'BY01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'DO01A',
        videoId: 'DO01A',
        standaloneVideoElementId: 'DO01A',
        videoURL: 'DO01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'IS01A',
        videoId: 'IS01A',
        standaloneVideoElementId: 'IS01A',
        videoURL: 'IS01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'JC01A',
        videoId: 'JC01A',
        standaloneVideoElementId: 'JC01A',
        videoURL: 'JC01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'LC01A',
        videoId: 'LC01A',
        standaloneVideoElementId: 'LC01A',
        videoURL: 'LC01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'MA01A',
        videoId: 'MA01A',
        standaloneVideoElementId: 'MA01A',
        videoURL: 'MA01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'NE01A',
        videoId: 'NE01A',
        standaloneVideoElementId: 'NE01A',
        videoURL: 'NE01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'SS01A',
        videoId: 'SS01A',
        standaloneVideoElementId: 'SS01A',
        videoURL: 'SS01A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    /* Audio: ArtWork */

    /* Audio: Artist */
    {
        paintingId: 'AL02A',
        videoId: 'AL02A',
        standaloneVideoElementId: 'AL02A',
        videoURL: 'AL02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'AN02A',
        videoId: 'AN02A',
        standaloneVideoElementId: 'AN02A',
        videoURL: 'AN02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'AP02A',
        videoId: 'AP02A',
        standaloneVideoElementId: 'AP02A',
        videoURL: 'AP02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'AY02A',
        videoId: 'AY02A',
        standaloneVideoElementId: 'AY02A',
        videoURL: 'AY02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'BY02A',
        videoId: 'BY02A',
        standaloneVideoElementId: 'BY02A',
        videoURL: 'BY02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'DO02A',
        videoId: 'DO02A',
        standaloneVideoElementId: 'DO02A',
        videoURL: 'DO02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'IS02A',
        videoId: 'IS02A',
        standaloneVideoElementId: 'IS02A',
        videoURL: 'IS02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'JC02A',
        videoId: 'JC02A',
        standaloneVideoElementId: 'JC02A',
        videoURL: 'JC02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'LC02A',
        videoId: 'LC02A',
        standaloneVideoElementId: 'LC02A',
        videoURL: 'LC02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'MA02A',
        videoId: 'MA02A',
        standaloneVideoElementId: 'MA02A',
        videoURL: 'MA02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'NE02A',
        videoId: 'NE02A',
        standaloneVideoElementId: 'NE02A',
        videoURL: 'NE02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    {
        paintingId: 'SS02A',
        videoId: 'SS02A',
        standaloneVideoElementId: 'SS02A',
        videoURL: 'SS02A.mp3',
        displayType: ANNOTATION_VIDEO_DISPLAY.AUDIO_PLAYER, // must set annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        headerText: '',
        bodyText: null,
        displayMesh: 'Y',
        useVideoMeshButton: 'Y',
        displayHeight: 90,
        videoAutoPlay: VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY,
        displayAudioSprite: 'N',
        // videoPlaySpriteImageURL: BSHF_AUDIO_RED,
        // videoPauseSpriteImageURL: BSHF_PAUSE_RED,
        // videoLoop: true,
    },
    /* Audio: Artist */
    /* Audio */

    /* R1 W1 */
    {
        // paintingId: 'ARC0101',
        paintingId: 'NE01',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}NE01.jpg/info.json`,
        headerText: `<h5>The Golden Bridge To Renewal</h5>`,
        bodyText: `The idea behind <i>The Golden Bridge To Renewal</i> is an aerial perspective looking down on nature growing back. The bottom left shows barren land with a golden bridge that humanity is invited to take in order to bring us over to the side of renewal. There, nature is growing back wildly and abundantly. River systems are healthy, green foliage and trees are growing back in abundance. There, nature is treated in such awe and gratitude for all it does for us, that we make looking after it at the core of our decisions. Can you spot the heart?`,
        hasChildren: 'Y',
        childPaintingId: ['NE02'],
        displayMesh: 'N',
        // urlLink1Label: ,
        // urlLink1: ,
    },
    {
        paintingId: 'NE02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}NE02.jpg/info.json`,
        headerText: `<h5>Natalie Ellis</h5>`,
        bodyText: `Natalie is inspired by nature, colour, and other things that make her feel in awe. She believes we can use our imagination to inspire positivity and bring about the changes we wish to see in the world.`,
        // hasChildren: 'Y',
        // childPaintingId: ['LC02'],
        instagramUrl: 'https://www.instagram.com/natalieellisart/',
        facebookUrl: 'https://www.facebook.com/natalieellisart',
        ContactURL: 'https://natalieellisart.com/',
    },
    /* R1 W1 */

    /* R1 W2 */
    {
        // paintingId: 'ARC0401',
        paintingId: 'AY01',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}AY01.jpg/info.json`,
        headerText: `<h5>Lift Up Our Planet</h5>`,
        bodyText: `<i>Lift Up Our Planet</i> was created with a mix of watercolours and pencil, and inspired by the messages of the 'David Attenborough: A Life On Our Planet' film on the ways we can all make a difference by taking responsibility and making conscious choices. From renewable energy sources; to supporting our oceans and forests; to helping raise people out of poverty, 'Lift Up Our Planet' portrays that each and every one of us has a role to play in supporting our planet the way it supports us.`,
        hasChildren: 'Y',
        childPaintingId: ['AY02'],
        displayMesh: 'N',
        // urlLink1Label: ,
        // urlLink1: ,
    },
    {
        paintingId: 'AY02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}AY02.jpg/info.json`,
        headerText: `<h5>Amber Young</h5>`,
        bodyText: `Amber takes inspiration from our beautiful planet and enjoys creating artwork that has the potential to encourage us to think about our actions and ways we can make a difference. Amber defines her personal identity through art, and knows the power and meaning art can hold in making change possible. `,
        instagramUrl: 'https://www.instagram.com/amberamzy',
    },
    {
        // paintingId: 'ARC0201',
        paintingId: 'SS01',
        videoId: null,
        videoURL: null,
        // iiifSource: `${CANTALOUPE_IMAGE_SERVER}SS01.jpg/info.json`,
        iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}SS01A.jpg/info.json`,
            `${CANTALOUPE_IMAGE_SERVER}SS01B.jpg/info.json`,
            `${CANTALOUPE_IMAGE_SERVER}SS01C.jpg/info.json`],
        headerText: `<h5>The World Beneath</h5>`,
        bodyText: `<i>The World Beneath</i> envisions a healthy future that we should work towards, illustrating a balance of thriving sea life and sustainable green cities.<br><br>Sophie utilised the method of layering multiple embroidery hoops to mirror the depths of the ocean, using this opportunity to learn stumpwork embroidery to represent the land and seafloor, incorporating goldwork embroidery techniques that she had learnt at university.<br><br>By using tulle’s transparency to gaze through the water, Sophie aimed to bring visibility to marine life. Although it may seem distant to many and is therefore neglected, human pollution has affected even the deepest parts of the ocean, with plastic finding its way even in the fish we eat. Additionally, the piece highlights the often overlooked seabed and imagines a flourishing reef ecosystem through the use of colourful and lustrous materials, as a contrast to the catastrophic alternative of coral bleaching.`,
        hasChildren: 'Y',
        childPaintingId: ['SS02'],
        displayMesh: 'N',
        // urlLink1Label: ,
        // urlLink1: ,
    },
    {
        paintingId: 'SS02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}SS02.jpg/info.json`,
        headerText: `<h5>Sophie Sidhu</h5>`,
        bodyText: `Sophie is a third-year Costume with Textiles student at the University Of Huddersfield, who specialises in embroidery and enjoys using fibre art to create intricate, detailed pieces. Sewing and crafting since she was a child, Sophie loves finding new techniques and methods to incorporate into her work.`,
        instagramUrl: 'https://www.instagram.com/bysophiesidhu',
        // facebookUrl: '',
        // ContactURL: '',
    },
    /* R1 W2 */

    /* R1 W3 */
    {
        // paintingId: 'ARC0301',
        paintingId: 'MA01',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}MA01.jpg/info.json`,
        headerText: `<h5>From Little Acorns Grow</h5>`,
        bodyText: `<i>From Little Acorns Grow</i> illustrates the beauty of togetherness in working towards restoring balance in our planet through even the smallest acts. This becomes visible with the children planting an oak tree - the king of trees - surrounded by approving wild animals local to Devon; people cycling and using electric cars; homes with solar panels, wind turbines and green roofs with bee hives. The sea turtles and golden toad symbolise the gradual loss of species due to climate change, contrasted with the lotus which symbolises enlightenment, self-generation and rebirth.<br><br>Drawing from her family roots, Mabel illustrates various ethnicities in her work, which she hopes will encourage a coming together of humankind to save life on earth.`,
        hasChildren: 'Y',
        childPaintingId: ['MA02'],
        displayMesh: 'N',
        // urlLink1Label: ,
        // urlLink1: ,
    },
    {
        paintingId: 'MA02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}MA02.jpg/info.json`,
        headerText: `<h5>Mabel Cheung Harris</h5>`,
        bodyText: `Mabel lives in Devon and spends her time working for the local council's Environment Group; creates and sells environmental art on Etsy to raise funds for wildlife charities; while also juggling family duties. She adores amphibians, reptiles, and birds which inspire her art and passion for raising awareness of wildlife conservation.`,
        instagramUrl: 'https://www.instagram.com/mabelart.devon/',
        facebookUrl: 'https://www.facebook.com/mabelart.devon/?ref=py_c',
        ContactURL: 'https://www.mabelart.com/',
    },

    {
        // paintingId: 'CRE0103',
        paintingId: 'IS01',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}IS01.jpg/info.json`,
        headerText: `<h5>Looking Forward</h5>`,
        bodyText: `<i>Looking Forward</i> is inspired by our planet, the pandemic, and pangolins. For Ismay, the COVID-19 pandemic highlighted the plight of the pangolin as one of many species being threatened by our actions. Ismay envisions a rewilded world where species are no longer at risk, a world which Ismay's grandmother helped to protect.<br><br>The fossils are symbols of past extinctions, which also serve as a reminder of the outcome for many species if necessary action is not taken. The dark tones are suggestive of the catastrophic impact we have had - and still have - on the environment. They are juxtaposed by the light, alluding to a sort of Eden; promising hope for a sustainable world, one which is still wild. The figure by the window conveys the sense of uncertainty we hold for the future, surrounded by darkness she portrays the pivotal moment we inhabit; one on the edge of change, for better or worse.`,
        hasChildren: 'Y',
        childPaintingId: ['IS02'],
        displayMesh: 'N',
        // urlLink1Label: ,
        // urlLink1: ,
    },
    {
        paintingId: 'IS02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}IS02.jpg/info.json`,
        headerText: `<h5>Ismay Alice Wells</h5>`,
        bodyText: `Ismay is an 18-year old artist from Cambridge that feels passionately about our natural world. Upon finishing her ‘A’ Levels, Ismay worked in commissioned portraiture and now uses a variety of mediums in her work, predominately acrylics.`,
        instagramUrl: 'https://www.instagram.com/twobytwoartcreations',
        // facebookUrl: '',
        // ContactURL: '',
    },

    {
        // paintingId: 'CRE0201',
        paintingId: 'AP01',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}AP01.jpg/info.json`,
        headerText: `<h5>Urban Jungle</h5>`,
        bodyText: `<i>Urban Jungle</i> reveals the beauty that could follow from humans living harmoniously with the natural world. Amy envisions a future where nature's importance is noticed by humanity.<br><br>The circle behind the figures represents the perfection and unity created by the two worlds combining. Whilst the city below is discrete it is still an important part of the piece and allows nature and the figure to be the focal points of this acrylic painting.`,
        hasChildren: 'Y',
        childPaintingId: ['AP02'],
        displayMesh: 'N',
        // urlLink1Label: ,
        // urlLink1: ,
    },
    {
        paintingId: 'AP02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}AP02.jpg/info.json`,
        headerText: `<h5>Amy Zara Preston</h5>`,
        bodyText: `Amy is an artist based in Hull that enjoys creating a diversity of acrylic paintings inspired by the beauty of nature, exploring the relationship between animals and civilisation to make surreal compositions.`,
        instagramUrl: 'https://www.instagram.com/zaraart.ap',
        // facebookUrl: '',
        // ContactURL: '',
    },
    {
        paintingId: 'LC01',
        videoId: 'LC01V',
        standaloneVideoElementId: 'LC01V',
        videoURL: 'LC01V.mp4',
        // iiifSource: `${CANTALOUPE_IMAGE_SERVER}.jpg/info.json`,
        headerText: '<h5>The Foundling</h5>',
        bodyText: `The Foundling gives voice to a boy who has experienced abandonment, separation and loss. To make sense of a human world of war and pollution, he turns to the piano and his dreams of nature. Here, anything is possible and the world is full of birds who fly free. It is a one-minute experimental documentary animated in charcoal. It was hand-drawn, with each frame drawn over the one before. It was commissioned by Animate Projects/Anim18 and created at Figuration, London.`,
        displayType: ANNOTATION_VIDEO_DISPLAY.ANNOTATION_VIDEO,
        displayMesh: 'N',
        // displayHeight: 90,
        videoAutoPlay: 'N',
        // videoPlaySpriteImageURL: '/assets/sprites/videoPlayButtonBlack.svg',
        // videoPauseSpriteImageURL: '/assets/sprites/videoPauseButtonBlack.svg',
        // videoButtonScale: {x: .2, y: .2, z: .2},
        // videoButtonPos:{x:9.17728, y:0.606917,z:11.6652},
        videoTextureEncoding: THREE.sRGBEncoding,
        // videoButtonPos:{x:-8.31443,y:-1.5 ,z:-29.7395 }
        useVideoMeshButton: 'Y',
        hasChildren: 'Y',
        childPaintingId: ['LC02'],
    },
    {
        paintingId: 'LC02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}LC02.jpg/info.json`,
        headerText: `<h5>Leo Crane</h5>`,
        bodyText: `Leo is an animator, painter and co-founder of Figuration, a creative social enterprise based in London. Leo's practice explores identity and belonging with a visual language rooted in movement and colour.`,
        // urlLink1Label: ,
        // urlLink1: ,
        instagramUrl: 'https://www.instagram.com/leocrane77',
        // facebookUrl: '',
        ContactURL: 'https://www.leocrane.co.uk',
    },
    // This is for testing only
    // {
    //     paintingId: 'AP03',
    //     videoId: null,
    //     videoURL: null,
    //     iiifSource: `${CANTALOUPE_IMAGE_SERVER}AP02.jpg/info.json`,
    //     headerText: `<h5>Amy Zara Preston</h5>`,
    //     bodyText: `Amy is an artist based in Hull that enjoys creating a diversity of acrylic paintings inspired by the beauty of nature, exploring the relationship between animals and civilisation to make surreal compositions.`,
    //     instagramUrl: 'https://www.instagram.com/zaraart.ap',
    //     // facebookUrl: '',
    //     // ContactURL: '',
    // },
    /* R1 W3 */

    /* R2 W1 */
    {
        // paintingId: 'CRE0301',
        paintingId: 'AL01',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}AL01.jpg/info.json`,
        headerText: `<h5>Bigger Than All Of Us</h5>`,
        bodyText: `Our ill-treatment of the planet has become abundantly clear in the last decade. We all live amongst creatures that could flourish without us, but we rarely see them. More often, we push them out, destroy their homes. Alfie re-imagines the world we live in, calling us all to live alongside wildlife.<br><br><i>Bigger Than All Of Us</i> reflects the ubiquity of nature, emphasising that nature is bigger than all of us and the earth doesn't need humans to survive. We need it.`,
        hasChildren: 'Y',
        childPaintingId: ['AL02'],
        displayMesh: 'N',
        // urlLink1Label: ,
        // urlLink1: ,
    },
    {
        paintingId: 'AL02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}AL02.jpg/info.json`,
        headerText: `<h5>Alfie Bryan</h5>`,
        bodyText: `Alfie is a non-binary illustrator, recent Falmouth University graduate and lover of nature from the East Midlands. He is interested in sustainability, conservation, and plant-based living. You can find him at your nearest wildlife sanctuary with a sketchbook in hand.`,
        instagramUrl: 'https://www.instagram.com/alfieillustration',
        // facebookUrl: '',
        // ContactURL: '',
    },

    {
        // paintingId: 'CRE0401',
        paintingId: 'DO01',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}DO01.jpg/info.json`,
        headerText: `<h5>For The Love Of Trees</h5>`,
        bodyText: `Donna writes that "many of today’s trees inherited Earth before us. These scions of magnificent ancestry help create air that we breathe, offer shade, house birdsong and hold wisdom in their limbs. Yet we routinely chop down mature trees – far more than necessary – despite many complex problems associated with tree loss, compared to minimal gains. Let us celebrate trees and the space they need to thrive. With a more considered approach to tree management, we can coexist in harmony".<br><br>Donna's perception of faces in unusual places and inanimate objects is called pareidolia. Donna hopes that her pareidolia drawing of London plane trees in Cambridge will spark your imagination about stories and secrets hosted by trees worldwide. She concludes that "for all of the good that they do, we owe trees our respect".<br><br><i>For The Love Of Trees</i> was inspired by Martin Bond’s photograph, available on his website:`,
        hasChildren: 'Y',
        childPaintingId: ['DO02'],
        displayMesh: 'N',
        // urlLink1Label: ,
        // urlLink1: ,
        // ContactURL: 'https://www.acambridgediary.co.uk/october-2015',
    },
    {
        paintingId: 'DO02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}DO02.jpg/info.json`,
        headerText: `<h5>Donna McLuskie</h5>`,
        bodyText: `Donna studied design at university and, upon graduation, drew construction details for skyscrapers and the DisneyWorld themepark. Inspired by nature’s beauty and complexity, Donna now uses the same technical pens to create pareidolia drawings. Through this obscure visual language - which shows perceived faces in unusual places - Donna shares her empathy for nature.`,
        // instagramUrl: '',
        facebookUrl: 'https://www.facebook.com/Donna-McLuskie-writer-and-artist-1580207452195643/',
        ContactURL: 'https://hiddencreaturesart.com/',
    },

    {
        // paintingId: 'POR0102',
        paintingId: 'JC01',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}JC01.jpg/info.json`,
        headerText: `<h5>Ark Of The Future</h5>`,
        bodyText: `Carried by the wind, a self-sustaining ark carries people through seas belonging to birds, cetaceans, and fish. The ship holds education, harmony, and compassion as values at its core. Humans of all ages, backgrounds, and creeds watch and learn about the ocean life around them, about the plants and pollinators onboard the boat, and about each other - focusing on the similarities they all share. The water that laps and foams against the hull of the ship is living. Life that exists on a grand scale, from microscopic algae to giant whales. The importance, interconnectedness, and diversity of the oceans inspire all activities onboard the boat as it travels on its journey.`,
        hasChildren: 'Y',
        childPaintingId: ['JC02'],
        displayMesh: 'N',
        // urlLink1Label: ,
        // urlLink1: ,
    },
    {
        paintingId: 'JC02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}JC02.jpg/info.json`,
        headerText: `<h5>Jack Cowley</h5>`,
        bodyText: `Jack has an academic background in marine biology and oceanography, which underpins his determination to address ecological issues through art. Shaped by his personal experience and scientific knowledge of the natural world, he focuses on transforming theories, techniques, and strategies into creative visual solutions that address the increasing disconnect between humans and the environment. Bringing the organic cycles, patterns and processes that occur in nature into focus within his work, Jack aims to communicate stories with a contemporary aesthetic that inspire positive change.`,
        instagramUrl: 'https://www.instagram.com/mrjboc',
        facebookUrl: 'https://www.facebook.com/jackcowleyillustration',
        ContactURL: 'https://jackcowley.co.uk',
    },
    /* R2 W1 */

    /* R2 W2 */
    {
        // paintingId: 'POR0201',
        paintingId: 'AN01',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}AN01.jpg/info.json`,
        headerText: `<h5>Hope Street</h5>`,
        bodyText: `Acknowledging the lack of green spaces to those living in deprived areas, <i>Hope Street</i> imagines an alternate reality where the natural world has re-asserted itself, allowing nature to be accessible to all. Breathing together as one community, the residents of 'Hope Street' care and give back to nature throughout their lives.<br><br>"Mother Earth invites you now to step forward into Hope Street and reawaken your senses. Step over the stones across the river on your way to school or work, taste and smell the herbs on the wall, feel the water and soil against your feet, listen to the birds sing as you head to the community greenhouse or just simply be" - <i>Hope Street</i>`,
        hasChildren: 'Y',
        childPaintingId: ['AN02'],
        displayMesh: 'N',
        // urlLink1Label: ,
        // urlLink1: ,
    },
    {
        paintingId: 'AN02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}AN02.jpg/info.json`,
        headerText: `<h5>Andrew Bristow</h5>`,
        bodyText: `Andrew was born in Liverpool and currently lives in Cumbria. He is a self-taught artist, learning skills naturally whilst also gaining knowledge from his father who went to art college. Andrew normally tours sustainably on his bicycle, volunteering with NGOs and painting across Europe and Africa.`,
        instagramUrl: 'https://www.instagram.com/andywatercolour',
        // facebookUrl: '',
        ContactURL: 'https://wildcyclist.com/art-work',
    },
    /* R2 W2 */

    /* R2 W3 */
    {
        // paintingId: 'POR0301',
        paintingId: 'BY01',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}BY01.jpg/info.json`,
        headerText: `<h5>A Brighter Future</h5>`,
        bodyText: `When asked to think about our future, we tend to imagine beautiful utopias, clean-cut cities, and permanently blue skies. The reimagined future strays away from fantastical imaginings of the world and onto something closer to home and the ways most people currently go about thier everyday routines in urban living.<br><br>Bethan believes that "change is a slow and intricate process, where most of us might not even notice the changes that stricter environmental laws and regulations will bring to our planet. But just because we can’t see it, doesn’t mean it isn’t happening or that it doesn’t matter".<br><br>For Bethan, the future looks similar to the way it is now. But brighter.`,
        hasChildren: 'Y',
        childPaintingId: ['BY02'],
        displayMesh: 'N',
        // urlLink1Label: ,
        // urlLink1: ,
    },
    {
        paintingId: 'BY02',
        videoId: null,
        videoURL: null,
        iiifSource: `${CANTALOUPE_IMAGE_SERVER}BY02.jpg/info.json`,
        headerText: `<h5>Bethan Yip</h5>`,
        bodyText: `Bethan is a self-taught artist who specializes in digital art, and brings her academic background in ecology and conservation to spread messages that truly resonate with people through art.`,
        // instagramUrl: '',
        facebookUrl: 'https://www.facebook.com/profile.php?id=100010371404554',
        // ContactURL: '',
    },
    /* R2 W3 */

    /* Wall Text */
    {
        // paintingId: 'POR0301',
        paintingId: 'ABWT01',
        videoId: null,
        videoURL: null,
        isMediaUsed: 'N',
        headerText: `<h5>JUST IMAGINE</h5>`,
        bodyText: `Imagine a future where we put nature at the heart of our decisions...<br><br>2021 is critical to ensure we get back in balance with nature, through making changes to lifestyles and laws. This year brings key political moments into the spotlight. We must seize these opportunities to influence our government and ensure they commit to protecting nature and our planet.<br><br>We are living through a pivotal opportunity for positive change for our planet. Art has a vital role in both imagining and building a better future.<br><br>Just Imagine is a unique opportunity to platform the hope of a reimagined future from creative communities across the UK. By listening to community voices, we can kick-start the conversation about the solutions to this crisis.<br><br>Join us in celebrating the artistic interpretations of our twelve winning artists, as they explore what our world would look like if people and nature coexisted in harmony.<br><br>Ask yourself: how can we work towards a better future for everyone?<br><br><i>Curated by Andrea van den Hoek Mejias. Andrea is a curator, art business consultant and project manager, who works alongside established and up-and-coming art galleries in London.</i>`,
        // urlLink1Label: ,
        // urlLink1: ,
        // annotation_location: ANNOTATION_LOCATION.TOP_RIGHT,
       /* annotation_location_horiz_offset: -0.1,
        annotation_location_vert_offset: -0.08,*/
        displayMesh: 'N',
    },
    {
        // paintingId: 'POR0301',
        paintingId: 'EXWT01',
        videoId: null,
        videoURL: null,
        isMediaUsed: 'N',
        headerText: `<h5>A BETTER FUTURE AWAITS</h5>`,
        bodyText: `We are the first generation that knows we are destroying the world, and the last that can do anything about it.<br><br>The world is our one shared home. Let’s save it together. From the bees to the bears, we all need a safe, healthy space to thrive.<br><br>We’ve seen what we can achieve and by working together, we can turn things around. We can help forests to regrow. We can bring endangered species back from the brink of extinction. And we can hold governments and businesses to account.<br><br>This is the year. This is the change. This is the solution. Be a part of the fight for our world.`,
        urlLink1Label: 'MAKE A DIFFERENCE',
        urlLink1: 'https://wwf.org.uk/one-shared-home?utm_source=exhibition&utm_medium=event&utm_campaign=just-imagine',
        /*annotation_location_horiz_offset: -0.1,
        annotation_location_vert_offset: -0.08,*/
        displayMesh: 'N'
    },

    /* Wall Text */
];

export {AnnotationsDB};
