import VideoDisplay from "./VideoDisplay";
import * as THREE from "three";
import {ANNOTATION_LAYER_CHANEL, VIDEO_LAYER_CHANEL, VIDEO_PLAYING_MODE} from "../../client-data/GlobalConstants";
import {
    VIDEO_SPRITE_COLOR,
    VIDEO_SPRITE_TEXTURE_ENCODING,
    VIDEO_SPRITE_TONEMAPPED
} from "../../client-data/clientOptions";

class VideoContainer {
    constructor(scene, camera, css3dScene, videoMeshButtonContainer) {

        this.scene = scene;
        this.camera = camera;
        this.css3dScene = css3dScene;
        this.videoDisplays = {};
        this.materialCache = {};
        this.autoAudioDisplay = {};
        this.videosGroup = new THREE.Group();
        this.videoMeshButtonContainer = videoMeshButtonContainer;
        scene.add(this.videosGroup);
        this.videosGroup.matrixAutoUpdate = false;
        this.videosGroup.updateMatrix();



        this.onDocumentMouseMove = this.onDocumentMouseMove.bind(this);
      //  window.addEventListener("click", this.onDocumentMouseMove, false);

        this.camera.layers.enable(VIDEO_LAYER_CHANEL);

    }

    removeMouseListeners = ()=> {
        window.removeEventListener("click", this.onDocumentMouseMove);
    }

    addMouseListeners = ()=> {
        window.addEventListener("click", this.onDocumentMouseMove, false);
    }

    createButtonMaterial = (SpriteImageURL,materialName) => {

        if (!this.materialCache[SpriteImageURL]) {
            //console.log("videoContainer createButtonMaterial will cache-",SpriteImageURL)
            const spriteMapPlay = new THREE.TextureLoader().load(SpriteImageURL);
            spriteMapPlay.encoding = VIDEO_SPRITE_TEXTURE_ENCODING;
           const spriteMaterialPlay = new THREE.SpriteMaterial({map: spriteMapPlay, color: VIDEO_SPRITE_COLOR, fog: true,name:materialName});
            spriteMaterialPlay.toneMapped = VIDEO_SPRITE_TONEMAPPED;
            spriteMapPlay.dispose();
            this.materialCache[SpriteImageURL] = spriteMaterialPlay;

        }

        return this.materialCache[SpriteImageURL];
    }

    resetScene = (scene)=> {
        this.scene = scene;
        this.scene.add(this.videosGroup);

        this.videosGroup.updateMatrix();
    }

    addVideoDisplay(mediaRecord, mesh) {
        const videoMeshButton = this.videoMeshButtonContainer.getVideoMeshButton(mediaRecord.paintingId);

        const videoDisplay = new VideoDisplay(mediaRecord, mesh, this.scene, this.css3dScene, this.camera,this.videosGroup,
            this.createButtonMaterial(mediaRecord.videoPlaySpriteImageURL,`VideoPlay-spriteMat-${mesh.userData.painting_id}`),
            this.createButtonMaterial(mediaRecord.videoPauseSpriteImageURL,`VideoPause-spriteMat-${mesh.userData.painting_id}`),
            videoMeshButton);
        this.videoDisplays[videoDisplay.mediaId] = videoDisplay;

        if (mediaRecord.videoAutoPlay === VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS || mediaRecord.videoAutoPlay === VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_WITH_BUTTONS) {
            this.autoAudioDisplay[videoDisplay.mediaId] = videoDisplay;
        }
    }

    toggleAutoAudio = (pause) => {
        if (pause) {
            for (const [key, value] of Object.entries(this.autoAudioDisplay)) {
                value.PauseVideo();
                console.log(`${key}: ${value}`);
            }
        }
        else {
            for (const [key, value] of Object.entries(this.autoAudioDisplay)) {
                value.playVideo();
                console.log(`${key}: ${value}`);
            }
        }
    }

    annotationSelected(videoDisplay) {

        if (videoDisplay.isPlaying) {

            videoDisplay.PauseVideo();
        }
        else {
            videoDisplay.playVideo();
        }
    }

    annotationUnSelected(videoDisplay) {

    }

    onDocumentMouseMove(event) {

        // event.preventDefault();
        let intersects = null;



        intersects = this.getIntersects(event.clientX, event.clientY);
        if (intersects && intersects.length > 0) {

            let res = intersects.filter(res => {

                return (res && (res.object.userData in this.videoDisplays));

            })[0];

            if (res && res.object && (res.object.userData in this.videoDisplays)) {

                this.selectedObject = res.object;
                this.annotationSelected(this.videoDisplays[this.selectedObject.userData]);
                return true;

            } else {

                if (this.selectedObject) {

                    this.annotationUnSelected(this.videoDisplays[this.selectedObject.userData]);
                    this.selectedObject = null;

                }
            }

        } else {
            if (this.selectedObject) {

                this.annotationUnSelected(this.videoDisplays[this.selectedObject.userData]);
                this.selectedObject = null;

            }
        }
        return false;

    }

    getIntersects(x, y) {

        const raycaster = new THREE.Raycaster();
        const mouseVector = new THREE.Vector3();
        raycaster.layers.enable(VIDEO_LAYER_CHANEL);


        x = (x / window.innerWidth) * 2 - 1;
        y = -(y / window.innerHeight) * 2 + 1;

        mouseVector.set(x, y, 0.5);
        raycaster.setFromCamera(mouseVector, this.camera);

        return raycaster.intersectObject(this.videosGroup, true);

    }

    get videoContainerGroup() {
        return this.videosGroup;
    }

    dispose = ()=> {
        // this.videoContainerGroup.remove(...this.videoContainerGroup.children);
        this.videoContainerGroup.traverse((object) => {
            object.remove(...object.children);
        });
        this.videoDisplays = {};
    }

    clearMaterialCache = () => {
        //console.log("VideoContainer clearMaterialCache ");
        this.materialCache = {};
    }

}

export default VideoContainer;
