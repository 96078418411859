import * as THREE from "three";
import {VideoMeshButton} from "./VideoMeshButton";

export class VideoMeshButtonContainer {

    private raycaster = new THREE.Raycaster();
    private mouseVector = new THREE.Vector3();
    private meshesObj: Record<string, VideoMeshButton> = {};
    private selectedObject: THREE.Object3D | null = null;

    constructor(private scene: THREE.Scene ,private camera: THREE.Camera, private buttons: Array<THREE.Mesh>) {
        buttons.forEach(btn => {
            this.meshesObj[btn.userData.videoButtonId] = new VideoMeshButton(this.scene,btn);
        })

    }

    getVideoMeshButton = ( videoButtonId: string) => {
        const btnRec = this.meshesObj[videoButtonId];
        if (btnRec) {
            return btnRec;
        }
        return null;

    }



    meshSelected = (videoButtonId: string, node: THREE.Object3D): void => {

        const btnRec = this.meshesObj[videoButtonId];

        if (btnRec) {
            btnRec.buttonClicked();
        }
    }

    hoverOver = (meshPaintingId: string, node: THREE.Object3D) => {

    }


    onDocumentMouseMove = (event: MouseEvent, useForHovering = false): boolean => {

        let intersects: THREE.Intersection[] = [];

        intersects = this.getIntersects(event.clientX, event.clientY);
        const intersectLength = intersects.length;
        if (intersectLength > 0) {


            let res = intersects[0];
            const videoButtonId = res.object.userData.videoButtonId;
            if (videoButtonId && (videoButtonId in this.meshesObj)) {

                this.selectedObject = res.object;
                if (useForHovering) {
                    this.hoverOver(this.selectedObject.userData.videoButtonId, this.selectedObject);
                } else {
                    this.meshSelected(this.selectedObject.userData.videoButtonId, this.selectedObject);
                }
                return true;
            }
        }

        return false;

    }

    getIntersects = (x: number, y: number): THREE.Intersection[] => {

        x = (x / window.innerWidth) * 2 - 1;
        y = -(y / window.innerHeight) * 2 + 1;

        this.mouseVector.set(x, y, 0.5);
        this.raycaster.setFromCamera(this.mouseVector, this.camera);

        return this.raycaster.intersectObjects(this.buttons, true);

    }
}
