import {Euler, Vector3, Scene} from "three";

const FLOOR_NUMBER = {
    BOTTOM_FLOOR: 1,
    TOP_FLOOR: 2,
};
// Here we split by Artists Names
//ChatRoom01
//ScreenStageLeft
const CameraLocationsDB = [
    {
        cameraLocationId: 'ABWT01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Just Imagine - Intro',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'NE01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Natalie Ellis',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'AY01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Amber Young',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'SS01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Sophie Sidhu',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'MA01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Mabel Cheung Harris',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'IS01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Ismay Alice Wells',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'AP01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Amy Zara Preston',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'LC01V',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Leo Michael Crane',
        ObjectId:null,
        displayMesh: 'N',
    },
    {
        cameraLocationId: 'DO01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Donna McLuskie',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'JC01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Jack Cowley',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'AL01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Alfie Bryan',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'AN01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Andrew Bristow',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'BY01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Bethan Yip',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'EXWT01N',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'A Better Future Awaits',
        ObjectId:null,
        displayMesh: 'Y',
    },
];

export {CameraLocationsDB, FLOOR_NUMBER};
