import React from "react";
import VideoComponent from "../util/VideoComponent";

const VideosComponent = (props) => {
    return (
        <div>

            {/*<VideoComponent id="M01V01" src={process.env.PUBLIC_URL + `/videos/M01V01.mp4`}/>*/}
            {/*<VideoComponent id="M01V02" src={process.env.PUBLIC_URL + `/videos/M01V02.mp4`}/>*/}
            <VideoComponent id="LC01V" src={process.env.PUBLIC_URL + `/videos/LC01V.mp4`}/>

            {/*/!*Audio*!/*/}
            <VideoComponent id="ABWT01A" src={process.env.PUBLIC_URL + `/audio/ABWT01A.mp3`} audio="Y"/>
            <VideoComponent id="AL01A" src={process.env.PUBLIC_URL + `/audio/AL01A.mp3`} audio="Y"/>
            <VideoComponent id="AL01CA" src={process.env.PUBLIC_URL + `/audio/AL01CA.mp3`} audio="Y"/>
            <VideoComponent id="AL02A" src={process.env.PUBLIC_URL + `/audio/AL02A.mp3`} audio="Y"/>
            <VideoComponent id="AN01A" src={process.env.PUBLIC_URL + `/audio/AN01A.mp3`} audio="Y"/>
            <VideoComponent id="AN01CA" src={process.env.PUBLIC_URL + `/audio/AN01CA.mp3`} audio="Y"/>
            <VideoComponent id="AN02A" src={process.env.PUBLIC_URL + `/audio/AN02A.mp3`} audio="Y"/>
            <VideoComponent id="AP01A" src={process.env.PUBLIC_URL + `/audio/AP01A.mp3`} audio="Y"/>
            <VideoComponent id="AP01CA" src={process.env.PUBLIC_URL + `/audio/AP01CA.mp3`} audio="Y"/>
            <VideoComponent id="AP02A" src={process.env.PUBLIC_URL + `/audio/AP02A.mp3`} audio="Y"/>
            <VideoComponent id="AY01A" src={process.env.PUBLIC_URL + `/audio/AY01A.mp3`} audio="Y"/>
            <VideoComponent id="AY01CA" src={process.env.PUBLIC_URL + `/audio/AY01CA.mp3`} audio="Y"/>
            <VideoComponent id="AY02A" src={process.env.PUBLIC_URL + `/audio/AY02A.mp3`} audio="Y"/>
            <VideoComponent id="BACA01" src={process.env.PUBLIC_URL + `/audio/BACA01.mp3`} audio="Y"/>
            <VideoComponent id="BY01A" src={process.env.PUBLIC_URL + `/audio/BY01A.mp3`} audio="Y"/>
            <VideoComponent id="BY01CA" src={process.env.PUBLIC_URL + `/audio/BY01CA.mp3`} audio="Y"/>
            <VideoComponent id="BY02A" src={process.env.PUBLIC_URL + `/audio/BY02A.mp3`} audio="Y"/>
            <VideoComponent id="DO01A" src={process.env.PUBLIC_URL + `/audio/DO01A.mp3`} audio="Y"/>
            <VideoComponent id="DO01CA" src={process.env.PUBLIC_URL + `/audio/DO01CA.mp3`} audio="Y"/>
            <VideoComponent id="DO02A" src={process.env.PUBLIC_URL + `/audio/DO02A.mp3`} audio="Y"/>
            <VideoComponent id="EXWT01A" src={process.env.PUBLIC_URL + `/audio/EXWT01A.mp3`} audio="Y"/>
            <VideoComponent id="IS01A" src={process.env.PUBLIC_URL + `/audio/IS01A.mp3`} audio="Y"/>
            <VideoComponent id="IS01CA" src={process.env.PUBLIC_URL + `/audio/IS01CA.mp3`} audio="Y"/>
            <VideoComponent id="IS02A" src={process.env.PUBLIC_URL + `/audio/IS02A.mp3`} audio="Y"/>
            <VideoComponent id="JC01A" src={process.env.PUBLIC_URL + `/audio/JC01A.mp3`} audio="Y"/>
            <VideoComponent id="JC01CA" src={process.env.PUBLIC_URL + `/audio/JC01CA.mp3`} audio="Y"/>
            <VideoComponent id="JC02A" src={process.env.PUBLIC_URL + `/audio/JC02A.mp3`} audio="Y"/>
            <VideoComponent id="LC01A" src={process.env.PUBLIC_URL + `/audio/LC01A.mp3`} audio="Y"/>
            <VideoComponent id="LC01CA" src={process.env.PUBLIC_URL + `/audio/LC01CA.mp3`} audio="Y"/>
            <VideoComponent id="LC02A" src={process.env.PUBLIC_URL + `/audio/LC02A.mp3`} audio="Y"/>
            <VideoComponent id="MA01A" src={process.env.PUBLIC_URL + `/audio/MA01A.mp3`} audio="Y"/>
            <VideoComponent id="MA01CA" src={process.env.PUBLIC_URL + `/audio/MA01CA.mp3`} audio="Y"/>
            <VideoComponent id="MA02A" src={process.env.PUBLIC_URL + `/audio/MA02A.mp3`} audio="Y"/>
            <VideoComponent id="NE01A" src={process.env.PUBLIC_URL + `/audio/NE01A.mp3`} audio="Y"/>
            <VideoComponent id="NE01CA" src={process.env.PUBLIC_URL + `/audio/NE01CA.mp3`} audio="Y"/>
            <VideoComponent id="NE02A" src={process.env.PUBLIC_URL + `/audio/NE02A.mp3`} audio="Y"/>
            <VideoComponent id="SS01A" src={process.env.PUBLIC_URL + `/audio/SS01A.mp3`} audio="Y"/>
            <VideoComponent id="SS01CA" src={process.env.PUBLIC_URL + `/audio/SS01CA.mp3`} audio="Y"/>
            <VideoComponent id="SS02A" src={process.env.PUBLIC_URL + `/audio/SS02A.mp3`} audio="Y"/>
            {/*<VideoComponent id="file_example_MP3_5MG" src={process.env.PUBLIC_URL + `/audio/file_example_MP3_5MG.mp3`} audio="Y"/>*/}
        </div>
    )
}

export default VideosComponent;
