import * as THREE from "three";
import {NavMeshContainer} from "../../navigation/ts/NavMeshContainer";
import {NavMeshPlayer} from "../../navigation/ts/NavMeshPlayer";
import {getNavigationTargetPosition, MeshTransform} from "../../navigation/ts/NavMeshUtils";
import * as TWEEN from "@tweenjs/tween.js";
import {Vector3} from "three";

const EMPTY_EULER = new THREE.Euler();
const EMPTY_QUATERNION = new THREE.Quaternion();
const VEC_UP = new Vector3(0,1,0);

export class CameraNavMeshHandler {

    private navmeshPlayer: NavMeshPlayer;
    private targetNode?:THREE.Object3D;
    private cameraYOffset:number = 1.5;
    private readonly raycaster = new THREE.Raycaster();
    private lookAt:boolean = false;
   // private
    constructor(private camera: THREE.PerspectiveCamera,private navMeshContainer:NavMeshContainer) {
        this.navmeshPlayer = this.navMeshContainer.createPlayer(this.camera,this.onInitPlayerCallBack,this.onStartPlayerCallBack,this.onUpdatePlayerCallBack,this.onCompletePlayerCallBack);
    }

    navigateToTargetPos =(node:THREE.Object3D)=> {
        this.lookAt = true;
        const target = getNavigationTargetPosition(node);
        this.targetNode = node;
        this.navmeshPlayer.navigateToTargetPos(target,true);
    }

    navigateToNanMeshPoint = (pos:THREE.Vector3) => {
        this.lookAt = true;
        const target:MeshTransform = {
            rotationEuler:EMPTY_EULER,
            position:pos,
            rotationQuaternion:EMPTY_QUATERNION,
        };
        this.navmeshPlayer.navigateToTargetPos(target,true,true);

    }

    onInitPlayerCallBack = (pos: THREE.Vector3,index:number,pathLength:number) => {
            this.cameraYOffset = this.camera.position.y - pos.y;

    }

    onUpdatePlayerCallBack = (pos:THREE.Vector3,index:number,pathLength:number) => {
        const offsetPos = pos.clone();
        offsetPos.y += this.cameraYOffset;
        this.camera.position.copy(offsetPos);
    }

    onStartPlayerCallBack = (pos:THREE.Vector3,index:number,pathLength:number) => {
        if (pathLength === 1) return;
        if (this.lookAt) {
            const offsetPos = pos.clone();
            offsetPos.y += this.cameraYOffset;
            // this.camera.lookAt(offsetPos);

            const startRotation = this.camera.quaternion.clone();

            this.camera.lookAt( offsetPos );
            const endRotation = this.camera.quaternion.clone();

            this.camera.quaternion.copy( startRotation );

            new TWEEN.Tween( this.camera.quaternion ).to( endRotation, 500 ).start();
        }
    }

    onCompletePlayerCallBack = (pos:MeshTransform) => {
        if (!pos.node) return;
        const worldDir = pos.node.getWorldDirection(new THREE.Vector3());
        const v2 = new THREE.Vector2(worldDir.z,worldDir.x);

        const E2 = new THREE.Euler(0,v2.angle(),0);

        const cameraWorld = this.camera.getWorldDirection(new THREE.Vector3());
        const camera2 = new THREE.Vector2(-cameraWorld.z,-cameraWorld.x);
        const ECamera = new THREE.Euler(0,camera2.angle() ,0);
        this.camera.rotation.copy(ECamera);

        new TWEEN.Tween(this.camera.rotation).to(
            {
                x: E2.x,
                y: E2.y,
                z: E2.z
            } , 1000)
            .start()
    }

    update = () => {
        this.navmeshPlayer.update();
    }

    onMouseDbClick = (event: MouseEvent): THREE.Vector3 | null => {
        const mouse = new THREE.Vector2();

        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

        this.camera.updateMatrixWorld();

        this.raycaster.setFromCamera( mouse, this.camera );

        const intersects = this.raycaster.intersectObject( this.navmeshPlayer.navMeshContainer.navMesh );

        if ( !intersects.length ) return null;

        return intersects[0].point.clone();


    }

    moveForward = (distance:number) => {
        if (distance === 0) return;

        let vec = new Vector3();

        vec.setFromMatrixColumn( this.camera.matrix, 0 );

        vec.crossVectors( VEC_UP, vec );


        const target = this.camera.position.clone();
        target.addScaledVector(vec,distance);
        this.moveToTarget(target);

    }

    moveSide = (distance:number) => {
        if (distance === 0) return;

        let vec = new Vector3();

        vec.setFromMatrixColumn( this.camera.matrix, 0 );

        const target = this.camera.position.clone();
        target.addScaledVector(vec,distance);
        this.moveToTarget(target);

    }

    private moveToTarget = (target:THREE.Vector3) => {

        this.camera.updateMatrixWorld(true);
        const intersectTarget = this.navmeshPlayer.rayTraceNaveMesh(target);
        if (intersectTarget.length) {
            const cameraPos = this.camera.position.clone();
            const intersectPlayer = this.navmeshPlayer.rayTraceNaveMesh(cameraPos);
            if (intersectPlayer.length) {
                target.y = intersectTarget[0].point.y;
                const playerOffsetY = cameraPos.y - intersectPlayer[0].point.y;
                const newPos = this.navmeshPlayer.clampStep(intersectPlayer[0].point, target);
                if (newPos) {
                        newPos.y += playerOffsetY;
                        this.camera.position.copy(newPos);

                } else {
                    console.log("CameraNavMeshHandler.moveToTarget clampStem is null");
                }
            }
            else {
                 console.log("moveForward Player no intersect");
            }
        }
        else {
             console.log("moveForward Target no intersect");
        }
    }

}
