import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import {ZONE_LINKS_LABEL, ZONE_HYPERLINKS_ALIGNMENT} from "../../client-data/clientOptions";
import {Container, Row, Col} from "react-bootstrap";
import ZoneLinksSubLevel from "../zones/ZoneLinksSubLevel";


class ZonesLinkModal extends Component {
    constructor(props) {
        super(props);

        this.hideModal = this.hideModal.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
        this.columnSize = this.columnSize.bind(this);
    }


    hideModal = (evt) => {
        this.props.close(evt);
    };

    onClickHandler(zoneObj) {
        // console.log("ZonesLinkModal onClickHandler:", zoneObj);
        this.props.onClickHandler(zoneObj);
    }

    columnSize(zonesData) {
        // console.log("[ZonesLinkModal] columnSizeCalculator: ", zonesData.length, zonesData.length);
        let mainColumnSize = 12;
        for (let i = 0; i < zonesData.length; i++) {
            // console.log("[ZonesLinkModal]", i);
            if (zonesData[i].cameraLocationLevel2 && zonesData[i].cameraLocationLevel2.length > 0) {
                // console.log("[ZonesLinkModal] inside the loop", i);
                return mainColumnSize = 6;
            }
        }
        return mainColumnSize;
    }

    parentCounter() {
        let parentCounter = 0;
        this.props.zonesData.forEach(dataObj => {
                if(dataObj.cameraLocationIdOfChild === 'N') {
                    parentCounter++;
                }
        });
        return parentCounter;
    }


    render() {

        let zonesLinks = null;
        if (this.props.zonesData && this.props.zonesData.length > 0) {
            const colSize = this.columnSize(this.props.zonesData);

            /* This variable "parentCounter" is used to count the appearance of the right-hand-side zone-hopping links
            * which are considered as parents, to be able to control the CSS so the last row (parent)
            * gets a border from the bottom to close the grid */
            let parentCounter = 0

            // console.log("[ZonesLinkModal] column Size: ", colSize);
            // console.log("[ZonesLinkModal] count parents: ", parentCounter);

            zonesLinks = this.props.zonesData.map((zoneObj, index) => {
                // let rowStlye = (index !== this.props.zonesData.length - 1) ? "zone-links-rows" : "zone-links-last-rows";

                if (zoneObj.cameraLocationIdOfChild === 'N') {

                    let rowStlye = (parentCounter !== (this.parentCounter() -1)) ? "zone-links-rows" : "zone-links-last-rows";
                    parentCounter++;
                    // console.log("ZonesLinkModal", zoneObj.cameraLocationId, rowStlye, index);

                    return (
                         <Row key={"row_" + zoneObj.cameraLocationId.toString()} className={rowStlye}>
                            <Col key={"col_" + zoneObj.cameraLocationId.toString()} xs={colSize}>
                                    <a key={"a_" + zoneObj.cameraLocationId.toString()}
                                       className={"programme-hyperlink"}
                                       href="#"
                                       onClick={() => this.onClickHandler(zoneObj)}
                                    >
                                        {zoneObj.locationName}
                                    </a>
                            </Col>
                            {<ZoneLinksSubLevel zoneCameraSubLevelLocations={zoneObj.cameraLocationLevel2}
                                                onClick={this.onClickHandler}/>}
                        </Row>
                    )
                }
            })
        }

        return (
            <div className="help-dialog">
                <Modal dialogClassName={"primaryModal modal-dialog modal-dialog-centered modal-dialog-scrollable"}
                       show={this.props.show}
                       onHide={this.hideModal} size={this.props.modelType}  backdrop="static">
                    <Modal.Header closeButton style={{border: 'none'}}>
                        <Modal.Title>{ZONE_LINKS_LABEL}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="zone_links">
                            {/*<div dangerouslySetInnerHTML={{__html: ZONE_LINKS_LABEL}}></div>*/}
                            <div style={ZONE_HYPERLINKS_ALIGNMENT}>
                                {/*{zonesLinks}*/}
                                <Container>
                                    {/*<Row style={{color: "#cf0044"}}>*/}
                                    {/*    <Col><b>MAIN BUILDING AREA</b></Col>*/}
                                    {/*    <Col><b>STANDS</b></Col>*/}
                                    {/*</Row>*/}
                                    {/*<hr style={{borderTop: "1.5px solid #00bcd5"}} />*/}
                                    {zonesLinks}
                                </Container>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ZonesLinkModal;
