import {Color} from "three";
import * as THREE from "three";

export const MATERIAL_PROPS_DB = [


    {
        materialName: "FloorMat",

        roughness: 0.40,
        // toneMapped:true,
    },

    {
        materialName: "GlassMat",
        glass: true,
        glassMatFunc: (envMap) => {
            return new THREE.MeshPhysicalMaterial({
                name: `GlassMat-MPM`,
                color: new THREE.Color(255, 255, 255),
                metalness: 0,
                roughness: 0,
                // alphaMap: texture,
                alphaTest: 0.5,
                envMap: envMap, //this.backgroundMap,
                envMapIntensity: .2,
                depthWrite: false,
                transmission: .8, // use material.transmission for glass materials
                opacity: 1, // set material.opacity to 1 when material.transmission is non-zero
                transparent: true
            });
        },
    },

    {
        materialName: "VideoButtonBaseMat",

        envMapIntensity: 4.179193713700034,

    },

    {
        materialName: "UVT_NE01",
        toneMapped: 'N',
    },
    {
        materialName: "UVT-1",
        toneMapped: 'N',
    },
    {
        materialName: "UVT-2",
        toneMapped: 'N',
    },
    {
        materialName: "UVT_LC01",
        toneMapped: 'N',
    },
    {
        materialName: "UVT_BY01",
        toneMapped: 'N',
    },
    {
        materialName: "Door_material",
        toneMapped: 'N',
    },
    {
        materialName: "Window_material",
        toneMapped: 'N',
    },


    {
        materialName: "Light-brown-frame-MAT",
        aOIntensity: 0.790133779264214,
        toneMapped: 'N',
    },


    {
        materialName: "WallMat",
        // aOIntensity: 0.872736590365562,
        aOIntensity: 0.8,
        envMapIntensity: 3.5669627605056373,
    },


    {
        materialName: "HDRIMaterial",
        toneMapped: 'N',
    },


    {
        materialName: "wallPanelEmmisiveMat",
        toneMapped: 'N',
    },

]



