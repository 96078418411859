import AnnotationContainer from '../../annotations/AnnotationContainer'
import VideoContainer from '../../annotations/VideoContainer'
import CircleJoystick from '../../joystick/CircleJoystick'
import {MeshContainer} from "../../annotations/ts/MeshContainer";
import {CameraNavMeshHandler} from "../../three-gltf-loader/ts/CameraNavMeshHandler";
import {VideoMeshButtonContainer} from "../../annotations/ts/VideoMeshButtonContainer";
import FPSNavigationControl from "../fps-navigation-control";

enum MouseClickTarget {
    NONE,
    Annotation,
    Video,
    Joystick,
    FPSNavigation,
    PaintingMesh,
    NAVMESH,
    VideoMeshBtn
}

class NavigationManager {

    private mouseTarget: MouseClickTarget = MouseClickTarget.NONE;
    private _isLocked: boolean = false;
    private readonly isTouchScreen: boolean;
    private readonly hammerManager?: HammerManager;

    constructor(private annotationContainer: AnnotationContainer,
                private videoContainer: VideoContainer,
                private meshContainer: MeshContainer,
                private joystick: CircleJoystick,
                private cameraNavMeshHandler: CameraNavMeshHandler,
                private videoMeshButtonContainer: VideoMeshButtonContainer,
                private fpsController: FPSNavigationControl
    ) {
        window.addEventListener("mousedown", this.onDocumentMouseMove, {passive: false});
        window.addEventListener("dblclick", this.onDocumentMouseDbClick, {passive: false});

        this.isTouchScreen = 'ontouchstart' in window;

        if (this.isTouchScreen) {
            this.hammerManager = new Hammer.Manager(document);
            this.hammerManager.add(new Hammer.Tap({event: 'doubletap', taps: 2}));
            this.hammerManager.add(new Hammer.Tap({event: 'singletap'}));
            this.hammerManager.get('doubletap').recognizeWith('singletap');
            this.hammerManager.get('singletap').requireFailure('doubletap');

            this.hammerManager.on("doubletap", (event => {
                this.onDocumentMouseDbClick(<MouseEvent>event.srcEvent);
            }));
        }
    }

    onDocumentMouseDbClick = (evt: MouseEvent) => {
        if (this._isLocked) {
            return;
        }
        if (this.meshContainer.onDocumentMouseMove(evt)) {
            if (!this._isLocked) {
                this.mouseTarget = MouseClickTarget.PaintingMesh;
            }
        } else {
            const navMeshHitPoint = this.cameraNavMeshHandler.onMouseDbClick(evt);
            if (navMeshHitPoint) {
                if (!this.isLocked) {
                    this.mouseTarget = MouseClickTarget.NAVMESH;
                    this.cameraNavMeshHandler.navigateToNanMeshPoint(navMeshHitPoint);

                }
            }
        }
    }

    onDocumentMouseMove = (evt: MouseEvent) => {

        if (this._isLocked) {
            return;
        }

        if (this.annotationContainer.onDocumentMouseMove(evt)) {
            if (!this._isLocked) {  //in case onDocumentMouseMove set the _isLocked to true or false before it returns
                this.mouseTarget = MouseClickTarget.Annotation;
                this.lockNavigations();
            }

        } else if (this.videoContainer.onDocumentMouseMove(evt)) {
            if (!this._isLocked) {
                this.mouseTarget = MouseClickTarget.Video;
                this.lockUnlockWithDelay();

            }
        } else if (this.videoMeshButtonContainer.onDocumentMouseMove(evt)) {
            if (!this._isLocked) {
                this.mouseTarget = MouseClickTarget.VideoMeshBtn;
                this.lockUnlockWithDelay();
            }
        }
    }

    public blockEvents = () => {
        this._isLocked = true;
        this.lockNavigations();
    }

    public unBlockEvents = () => {
        this._isLocked = false;
        this.unlockNavigations();
    }

    get isLocked() {
        return this._isLocked;
    }

    lockNavigations = () => {
        this.joystick.resetMouseThumb();
        this.fpsController.lockByNavigationManager();
    }

    unlockNavigations = () => {
        this.fpsController.unlockByNavigationManager();
    }

    lockUnlockWithDelay = (delay: number = 1000) => {
        this.lockNavigations();
        setTimeout(args => {
            this.unlockNavigations();
        }, delay);
    }
}

export default NavigationManager;
